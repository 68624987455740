@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';
@import 'animate';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800&display=swap');

//Animate
@import '~animate.css/animate.min';

// ICONS
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// DataTables
@import '~dataTables.net-bs4/css/dataTables.bootstrap4.css';

@import 'variables';
@import '_style.scss';
